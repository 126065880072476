<div class="paginator-container">

  <div class="paginator-controls">
    <button mat-icon-button (click)="goToPreviousPage()" [disabled]="pager?.currentPage === 1">
      <i class="far fa-chevron-left pl-0 w-36 navigation-arrow"></i>
    </button>
    <ng-container *ngFor="let page of getPages()" class="paginator-pages">
      <button class="btn-pagination btn-primary-unselected" *ngIf="page === '...'">{{ page }}</button>
      <button class="btn-pagination btn-primary-unselected" *ngIf="page !== '...' && page !== pager?.currentPage" (click)="goToPage(page)">{{ page }}</button>
      <button class="btn-pagination btn-primary" *ngIf="page !== '...' && page === pager?.currentPage">{{ page }}</button>
    </ng-container>
    <button mat-icon-button (click)="goToNextPage()" [disabled]="pager?.currentPage === totalPages || !totalItems">
      <i class="far fa-chevron-right pr-0 w-36 navigation-arrow"></i>
    </button>
  </div>
  <div class='navigation-info'>
    <div class="paginator-page-size">
      Items per page
      <mat-form-field>
        <mat-select [(value)]="pageSize">
          <mat-option
            *ngFor="let option of pageSizeOptions"
            [value]="option"
            (click)="selectPageSize(option)"
            class="page-size-options"
          >{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
<!--
    <div class="paginator-info">
      {{ getRangeStart() }} - {{ getRangeEnd() }} from {{ totalItems }}
    </div>-->
  </div>
</div>
